@import 'styles/variables';

.TooltipText {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    color: $white;
    @include font-regular-sm;
    line-height: 152.5%;

    svg {
        margin-right: 0.5rem;
    }
}

.IconCheck {
    path {
        fill: $success;
    }
}

.IconClose {
    path {
        fill: $gray-600;
    }
}
