@import 'styles/variables';

.Root {
    @include reset-button;
    line-height: 1.25em;
}

.Variant {
    &-contained {
        border: 0;
    }

    &-outlined {
        background-color: transparent;
        border: 1px solid;
    }

    &-text {
        border: 0;
        background-color: transparent;
    }
}

.Color {
    &-primary {
        color: $white;
        background-color: $primary;

        @include mouse-only {
            &:hover {
                color: $white;
                background-color: $gray-900;
            }
        }

        &:active {
            color: $white;
            background-color: $gray-600;
        }

        &.Variant-outlined {
            color: $primary;
            background-color: transparent;

            @include mouse-only {
                &:hover {
                    color: $white;
                    border-color: $gray-900;
                    background-color: $gray-900;
                }
            }

            &:active {
                color: $white;
                border-color: $gray-600;
                background-color: $gray-600;
            }
        }

        &.Variant-text {
            color: $primary;
            background-color: transparent;

            @include mouse-only {
                &:hover {
                    background-color: $primary-extra-light;
                }
            }

            &:active {
                background-color: $primary-light;
            }
        }

        &.Disabled {
            background-color: $primary-extra-light;
            border-color: $primary-extra-light;
            color: $white;
        }
    }

    &-secondary {
        color: $dark;
        background-color: $gray-400;

        @include mouse-only {
            &:hover {
                background-color: $gray-500;
            }
        }

        &:active {
            color: $white;
            background-color: $dark;
        }

        &.Variant-outlined {
            background-color: transparent;
            border-color: $gray-400;

            @include mouse-only {
                &:hover {
                    color: $gray-900;
                    background-color: $gray-400;
                }
            }

            &:active,
            &.Active {
                color: $white;
                border-color: $dark;
                background-color: $dark;
            }
        }

        &.Variant-text {
            color: $dark;
            background-color: transparent;

            @include mouse-only {
                &:hover {
                    background-color: $gray-300;
                }
            }

            &:active {
                background-color: $gray-400;
            }
        }

        &.Disabled {
            background-color: $primary-extra-light;
            border-color: $primary-extra-light;
            color: $white;
        }

        &.Dark {
            background-color: $gray-900;
            color: $white;

            @include mouse-only {
                &:hover {
                    background-color: $gray-700;
                    border-color: $gray-700;
                }
            }

            &:active {
                color: $dark;
                background-color: $gray-500;
                border-color: $gray-500;
            }

            &.Variant-outlined {
                background-color: transparent;
                color: $white;
                border-color: $gray-800;

                @include mouse-only {
                    &:hover {
                        background-color: $gray-900;
                        border-color: $gray-900;
                    }
                }

                &:active {
                    background-color: $gray-700;
                    border-color: $gray-700;
                }
            }

            &.Disabled {
                background-color: transparent;
                border: 1px solid $gray-900;
                color: $gray-800;
            }
        }
    }

    &-gray {
        color: $gray-900;
        background-color: $gray-200;

        @include mouse-only {
            &:hover {
                background-color: $gray-400;
            }
        }

        &:active {
            color: $white;
            background-color: $gray-900;
        }

        &.Variant-outlined {
            background-color: transparent;

            @include mouse-only {
                &:hover {
                    border-color: $gray-400;
                    background-color: $gray-400;
                }
            }

            &:active {
                color: $white;
                border-color: $gray-900;
                background-color: $gray-900;
            }
        }

        &.Variant-text {
            color: $gray-700;
            background-color: transparent;

            @include mouse-only {
                &:hover {
                    background-color: $gray-200;
                }
            }

            &:active {
                color: $gray-800;
                background-color: $gray-500;
            }
        }

        &.Disabled {
            background-color: $primary-extra-light;
            border-color: $primary-extra-light;
            color: $white;
        }
    }

    &-danger {
        color: $white;
        background-color: $danger;

        @include mouse-only {
            &:hover {
                background-color: $gray-900;
            }
        }

        &:active {
            color: $white;
            background-color: $gray-600;
        }

        &.Variant-outlined {
            background-color: transparent;
            border-color: $danger;
            color: $danger;

            @include mouse-only {
                &:hover {
                    background-color: $danger-extra-light;
                    border-color: $danger-extra-light;
                }
            }

            &:active {
                color: $white;
                border-color: $danger;
                background-color: $danger;
            }
        }

        &.Variant-text {
            color: $danger;
            background-color: transparent;

            @include mouse-only {
                &:hover {
                    background-color: $danger-extra-light;
                }
            }

            &:active {
                background-color: $danger-light;
            }
        }

        &.Disabled {
            background-color: $danger-extra-light;
            border-color: $danger-extra-light;
            color: $danger-light;
        }
    }
}

.Loader {
    color: $gray-500;

    &Wrapper {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $disabled;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: inherit;

        .Dark & {
            background-color: $gray-900;
            border: 0;
        }
    }

    .Dark & {
        color: $gray-700;
    }
}

.Size {
    &-large {
        font-size: $font-size-sm;
        padding: 0.9465em 1.2857em;
        border-radius: 12px;
        max-height: 44px;

        &:focus-visible {
            @extend %focus;
            border-radius: 12px;
        }

        .Preloader {
            width: 1.25em;
            height: 1.25em;
        }
    }

    &-medium {
        font-size: $font-size-sm;
        padding: 0.661em 1em;
        border-radius: 10px;
        max-height: 36px;

        &:focus-visible {
            @extend %focus;
            border-radius: 10px;
        }
    }

    &-small {
        font-size: $font-size-xs;
        padding: 0.542em 0.8333em;
        border-radius: 8px;
        max-height: 28px;

        &:focus-visible {
            @extend %focus;
            border-radius: 8px;
        }

        .Preloader {
            width: 0.75em;
            height: 0.75em;
        }
    }
}

.Disabled {
    pointer-events: none;
    user-select: none;
    border: 0;

    &:focus-visible {
        outline: none;
        box-shadow: none;
    }
}

.FullWidth {
    width: 100%;
}

.Icon {
    &Start {
        margin-right: 6px;
    }

    &End {
        margin-left: 6px;
    }
}

.OnlyIcon {
    .Icon {
        margin-left: 0;
        margin-right: 0;
    }

    &.Round {
        border-radius: 50%;
        max-height: none;
        padding: 1em;
    }

    &.Size-large {
        width: 3.143em;
        height: 3.143em;
    }

    &.Size-medium {
        width: 2.5715em;
        height: 2.5715em;
    }

    &.Size-small {
        width: 2.3335em;
        height: 2.3335em;
    }
}
