@import 'styles/variables';

$modal-easing-animation: cubic-bezier(0.68, -0.55, 0.265, 1.55);
$modal-easing-overlay: cubic-bezier(0.4, 0, 0.2, 1);

$modal-duration-fade-in: 400ms;
$modal-duration-fade-out: 300ms;

$modal-duration-slide-in: 400ms;
$modal-duration-slide-out: 300ms;

$modal-duration-bounce-in: 700ms;
$modal-duration-bounce-out: 500ms;

.Root {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: $z-index-modal;
}

.Overlay {
    background: $overlay;
    z-index: -1;
    opacity: 0;
    transition: opacity 500ms $modal-easing-overlay;

    &[data-status='open'] {
        opacity: 1;
    }

    &[data-status='initial'],
    &[data-status='close'] {
        opacity: 0;
    }
}

.Header {
    position: relative;
    background-color: $white;
    padding: 1.5rem 2rem 0.5rem;

    &Container {
        display: flex;
        justify-content: space-between;

        .CloseButton {
            position: absolute;
            top: 1.4375rem;
            right: 1.125rem;
            z-index: 1;
        }

        .HeaderBackButton {
            margin-left: -0.75rem;
            margin-right: 1rem;
        }
    }

    &Title {
        margin-bottom: 0;

        &Align {
            &-left {
                text-align: left;
            }

            &-center {
                width: 100%;
                text-align: center;

                &.HeaderTitleWithBackButton:not(.HeaderTitleWithCloseButton) {
                    padding-right: 2.25rem;
                }

                &.HeaderTitleWithCloseButton:not(.HeaderTitleWithBackButton) {
                    padding-left: 2rem;
                }
            }

            &-right {
                width: 100%;
                text-align: right;
            }
        }

        &.HeaderTitleWithCloseButton {
            padding-right: 2.5rem;
        }
    }

    &Description {
        margin-top: 0.5rem;
        font-size: $font-size-sm;
        color: $gray-700;
    }
}

.Body {
    flex-grow: 1;
    overflow-y: auto;
    padding: 2rem;
    position: relative;
}

.Footer {
    position: relative;
    padding: 2rem;
}

.Header + .Body {
    padding-top: 0.5rem;
}

.Header + .Footer {
    padding-top: 0.5rem;
}

.Body + .Footer {
    margin-top: -1.5rem;
    padding-top: 0.5rem;
}

.Container {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 2rem;
    background-color: $white;
    border-radius: 1rem;
    text-align: left;
    outline: 0;
    overflow: hidden;

    &FullWidth {
        width: calc(100% - 4rem);
    }

    &.MaxWidth {
        &-xs {
            max-width: 280px;
        }

        &-sm {
            max-width: 390px;
        }

        &-md {
            max-width: 580px;
        }

        &-lg {
            max-width: 680px;
        }

        &-xl {
            max-width: 910px;
        }
    }

    &FullScreen {
        height: 100%;
        width: 100%;
        margin: 0;
        display: flex !important;
        border-radius: 0;
        max-height: none !important;

        .Header {
            @include media-breakpoint-down(sm) {
                padding-left: 1rem;
                padding-right: 1rem;

                &::after {
                    left: 1rem;
                    right: 1rem;
                }
            }
        }

        .Body {
            @include media-breakpoint-down(sm) {
                padding-left: 1rem;
                padding-right: 1rem;
            }
        }

        .Footer {
            @include media-breakpoint-down(sm) {
                padding-left: 1rem;
                padding-right: 1rem;

                &::after {
                    left: 1rem;
                    right: 1rem;
                }
            }
        }
    }
}

.Wrapper {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;

    .Container {
        max-height: calc(100% - 4rem);
        overflow-y: auto;
    }

    &Scroll {
        &-body {
            display: block;
            overflow: hidden auto;
            text-align: center;

            .Container {
                max-height: none;
                display: inline-block;
                vertical-align: middle;
            }

            &::after {
                content: '';
                display: inline-block;
                vertical-align: middle;
                height: 100%;
                width: 0;
            }
        }

        &-container {
            .Header {
                &::after {
                    content: '';
                    position: absolute;
                    top: 100%;
                    height: 0.5rem;
                    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
                    left: 2rem;
                    right: 2rem;
                    z-index: 1;
                    pointer-events: none;
                }
            }

            .Body {
                min-height: 3rem;
            }

            .Footer {
                &::after {
                    content: '';
                    position: absolute;
                    bottom: 100%;
                    height: 0.5rem;
                    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
                    left: 2rem;
                    right: 2rem;
                    z-index: 1;
                    pointer-events: none;
                }
            }

            .Body + .Footer {
                margin-top: 0;
            }
        }
    }

    &FullScreen {
        display: flex;

        .Header {
            &::after {
                content: '';
                position: absolute;
                top: 100%;
                height: 0.5rem;
                background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
                left: 2rem;
                right: 2rem;
                z-index: 1;
                pointer-events: none;

                @include media-breakpoint-down(sm) {
                    left: 1rem;
                    right: 1rem;
                }
            }
        }

        .Body {
            min-height: 5rem;
        }

        .Footer {
            margin-top: auto;
            padding-top: 1rem;

            &::after {
                content: '';
                position: absolute;
                bottom: 100%;
                height: 0.5rem;
                background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
                left: 2rem;
                right: 2rem;
                z-index: 1;
                pointer-events: none;

                @include media-breakpoint-down(sm) {
                    left: 1rem;
                    right: 1rem;
                }
            }
        }
    }
}

.Transition {
    transition-property: transform;

    &-fade {
        &[data-status='initial'] {
            opacity: 0;
        }

        &[data-status='open'] {
            opacity: 1;
            transition: opacity $modal-duration-fade-in;
        }

        &[data-status='close'] {
            opacity: 0;
            transition: opacity $modal-duration-fade-out;
        }
    }

    &-slide-up,
    &-bounce-up {
        &[data-status='initial'] {
            transform: translateY(-100%);
        }

        &[data-status='open'] {
            transform: translateY(0);
            transition: $modal-duration-slide-in;
        }

        &[data-status='close'] {
            transform: translateY(-100%);
            transition: $modal-duration-slide-out;
        }
    }

    &-bounce-up {
        &[data-status='open'] {
            transition: transform $modal-duration-bounce-in $modal-easing-animation;
        }

        &[data-status='close'] {
            transition: transform $modal-duration-bounce-out $modal-easing-animation;
        }
    }

    &-slide-down,
    &-bounce-down {
        &[data-status='initial'] {
            transform: translateY(100%);
        }

        &[data-status='open'] {
            transform: translateY(0);
            transition: $modal-duration-slide-in;
        }

        &[data-status='close'] {
            transform: translateY(100%);
            transition: $modal-duration-slide-out;
        }
    }

    &-bounce-down {
        &[data-status='open'] {
            transition: transform $modal-duration-bounce-in $modal-easing-animation;
        }

        &[data-status='close'] {
            transition: transform $modal-duration-bounce-out $modal-easing-animation;
        }
    }

    &-slide-left,
    &-bounce-left {
        &[data-status='initial'] {
            transform: translateX(-100%);
        }

        &[data-status='open'] {
            transform: translateX(0);
            transition: $modal-duration-slide-in;
        }

        &[data-status='close'] {
            transform: translateX(-100%);
            transition: $modal-duration-slide-out;
        }
    }

    &-bounce-left {
        &[data-status='open'] {
            transition: transform $modal-duration-bounce-in $modal-easing-animation;
        }

        &[data-status='close'] {
            transition: transform $modal-duration-bounce-out $modal-easing-animation;
        }
    }

    &-slide-right,
    &-bounce-right {
        &[data-status='initial'] {
            transform: translateX(100%);
        }

        &[data-status='open'] {
            transform: translateX(0);
            transition: $modal-duration-slide-in;
        }

        &[data-status='close'] {
            transform: translateX(100%);
            transition: $modal-duration-slide-out;
        }
    }

    &-bounce-right {
        &[data-status='open'] {
            transition: transform $modal-duration-bounce-in $modal-easing-animation;
        }

        &[data-status='close'] {
            transition: transform $modal-duration-bounce-out $modal-easing-animation;
        }
    }
}
