@import 'styles/variables';

.Root {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $dark;
    padding: 1rem;
    min-height: 3.625rem;
    border-bottom: 1px solid $gray-900;

    @include media-breakpoint-up(md) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        min-height: 4.25rem;
    }

    @include media-breakpoint-up(xl) {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

.Logo {
    @include media-breakpoint-up(lg) {
        margin-right: 1.25rem;
    }
}

.StoreRoot {
    margin: auto;
}

.Left {
    display: flex;

    @include media-breakpoint-down(lg) {
        width: 100%;
    }
}

.Right {
    display: flex;

    & > *:not(:last-child) {
        margin-right: 0.5rem;
    }
}

.TelegramIcon {
    color: #29a8ea;
    background: $white;
    border-radius: 50%;
}

.Profile {
    &Icon {
        color: $primary;
        background: rgba($primary, 0.2);
        border-radius: 50%;
    }
}

.Logout {
    &Button {
        border: 0;
        color: $gray-700 !important;

        &:hover {
            color: $gray-600 !important;
            background: transparent !important;
        }

        &:active {
            color: $white !important;
            background-color: $gray-800 !important;
        }
    }

    &Icon {
        font-size: $font-size-xs;
    }
}

.Trigger {
    &Active {
        background: $gray-600;
    }

    &Icon {
        overflow: visible;
    }
}

.CreateOptionButton {
    pointer-events: auto;
    border-radius: 0 !important;

    &:focus-visible {
        box-shadow: 0 0 0 2px $info inset;
    }
}

.ListItem {
    padding: 0 !important;

    &:first-of-type .CreateOptionButton {
        border-radius: 0.75rem 0.75rem 0 0 !important;
    }

    &:last-of-type .CreateOptionButton {
        border-radius: 0 0 0.75rem 0.75rem !important;
    }
}
