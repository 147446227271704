@import 'styles/variables';

.Root {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
}

.Form {
    &Wrapper {
        width: 320px;
        margin: 0 auto 2rem;
    }

    &Login {
        &Input {
            margin-top: 1rem;
        }
    }

    &Password {
        &Input {
            margin-top: 1rem;
        }
    }

    &Submit {
        &Button {
            margin-top: 1rem;
        }
    }

    &Footer {
        display: flex;
        justify-content: space-between;
        margin-top: 1rem;
    }
}

.Qr {
    min-width: 332px;
    min-height: 430px;
    background: $gray-200;
    border-radius: 2rem;
    padding: 2rem;
    margin: 0 auto 2rem;
}

.PasswordError {
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25;
    letter-spacing: -0.03em;
    color: $danger;
    margin-top: 0.5rem;
}
