@import './variables';

// prettier-ignore
h1, h2, h3, h4, h5, h6, p,
.h1, .h2, .h3, .h4, .h5, .h6 {
    letter-spacing: -0.03rem;
    line-height: 1.5;
    margin-bottom: 0.75rem;
}

// prettier-ignore
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    font-weight: $font-weight-bold;
}

h1,
.h1 {
    font-size: $font-size-xxxl;
}

h2,
.h2 {
    font-size: $font-size-xxl;
}

h3,
.h3 {
    font-size: $font-size-xl;
}

h4,
.h4 {
    font-size: $font-size-lg;
}

h5,
.h5 {
    font-size: $font-size;
}

h6,
.h6 {
    font-size: $font-size-sm;
}

a {
    text-decoration: none;
    color: $primary;
    letter-spacing: -0.03rem;

    &:not([class]) {
        border-bottom: 1px solid transparent;
        transition: $link-transition;

        @include mouse-only {
            &:hover {
                color: $primary;
                border-bottom: 1px solid currentColor;
            }

            &:active {
                color: $text;
            }
        }

        &:focus-visible {
            @extend %focus;
        }
    }
}

ul {
    &:not([class]) {
        padding-left: 0;
        list-style-type: none;

        & > li {
            position: relative;
            padding: 0 0 0 1rem;

            &::before {
                content: '';
                position: absolute;
                border-radius: 50%;
                width: 1em;
                height: 1em;
                font-size: 0.5rem;
                background-color: $primary;
                top: 1em;
                left: 0;
            }

            & > ul:not([class]) > li {
                padding: 0 0 0 1rem;

                &::before {
                    background-color: transparent;
                    border: 1px solid $primary;
                }
            }
        }
    }
}

ol {
    &:not([class]) {
        padding-left: 0;
        list-style-type: none;
        counter-reset: list 0;

        & > li {
            position: relative;
            padding: 0 0 0 1.2rem;

            &::before {
                content: counter(list, decimal) '.';
                counter-increment: list;
                position: absolute;
                left: 0;
                color: $primary;
                font-weight: 500;
                letter-spacing: -0.05rem;
            }

            & > ol:not([class]) {
                counter-reset: list2 0;

                & > li {
                    padding: 0 0 0 2em;

                    &::before {
                        content: counter(list, decimal) '.' counter(list2, decimal) '.';
                        counter-increment: list2;
                    }
                }
            }
        }
    }
}

.fs-sm {
    font-size: $font-size-sm;
}

.fs-xs {
    font-size: $font-size-xs;
}

.fs-xxs {
    font-size: $font-size-xxs;
}

.fw-normal {
    font-weight: $font-weight-normal;
}

.fw-bold {
    font-weight: $font-weight-bold;
}
