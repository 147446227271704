@import 'styles/variables';

.Component {
    align-self: stretch;
    flex-grow: 1;
    background: $white;
    padding: 16px;
    box-shadow: $shadow-2;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    @include media-breakpoint-down(xl) {
        background: transparent;
        box-shadow: none;
    }

    h3 {
        margin: 6px 0 12px;
        font-size: $font-size-xxl;
        line-height: 130%;
    }

    p {
        font-size: $font-size;
        line-height: 140%;
        color: $gray-600;
        margin: 0;
        font-weight: $font-weight-normal;
        white-space: pre-wrap;
    }

    button {
        margin-top: 16px;
    }
}
