@import '~react-toastify/scss/variables';

// Rewrite toast variables
$rt-namespace: 'Toastify';
$rt-mobile: 'only screen and (max-width : 576px)';

@import '~react-toastify/scss/toastContainer';
@import '~react-toastify/scss/theme';
@import '~react-toastify/scss/toast';
@import '~react-toastify/scss/progressBar';

// Entrance and exit animations
@import '~react-toastify/scss/animations/bounce';
@import '~react-toastify/scss/animations/zoom';
@import '~react-toastify/scss/animations/flip';
@import '~react-toastify/scss/animations/slide';

@import '../../styles/variables';

:root {
    --toastify-color-light: #{$white};
    --toastify-color-dark: #{$dark};
    --toastify-color-info: #{$info};
    --toastify-color-success: #{$success};
    --toastify-color-warning: #{$warning};
    --toastify-color-error: #{$danger};
    --toastify-toast-width: 320px;
    --toastify-toast-background: #fff;
    --toastify-toast-min-height: 48px;
    --toastify-toast-max-height: 800px;
    --toastify-z-index: #{$z-index-toast};
}

.Toastify__toast {
    padding: 0;

    &:last-of-type {
        margin-bottom: 0;
    }

    box-shadow: $shadow-1;

    @include media-breakpoint-up(sm) {
        border-radius: 0.75rem;
    }
}

.Toastify__toast-body {
    padding: 0;

    .Toastify__alert {
        border-radius: inherit;
        color: $text;
        background-color: $white;
    }
}

.Toastify__close-button {
    position: absolute;
    right: 0.5rem;
    top: 0.625rem;
}
