@import 'styles/variables';

.Root {
    width: max-content;
    background-color: $dark;
    color: $white;
    padding: 1rem;
    border-radius: $border-radius-sm;
    letter-spacing: $letter-spacing-base;
    z-index: $z-index-tooltip;
}

.Dense {
    font-size: $font-size-sm;
    padding: 0.625rem 0.8rem;
}

.Dark {
    color: $dark;
    background-color: $gray-300;
}

.Arrow {
    fill: $dark;

    &Dark {
        fill: $white;
    }
}
