// Bootstrap variables
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/variables-dark';

// Rewrite Bootstrap variables
// Prefix for css variables. Example: color: var(--css-primary).
$prefix: 'css-';

// Colors
$gray-200: #f6f8fd;
$gray-300: #edf2f8;
$gray-400: #d8e1ee;
$gray-500: #bbc6d6;
$gray-600: #8391a4;
$gray-700: #516074;
$gray-800: #253449;
$gray-900: #1f2c3f;

$primary: #4672e6;
$primary-light: #bacdff;
$primary-extra-light: #e8edfc;

$info: #50b5ff;
$info-light: lighten($info, 20%);
$info-extra-light: lighten($info, 30%);

$success: #3c9a88;
$success-light: lighten($success, 30%);
$success-extra-light: #e0f1ee;

$warning: #e49153;
$warning-light: lighten($warning, 20%);
$warning-extra-light: lighten($warning, 30%);

$danger: #e13b27;
$danger-light: lighten($danger, 30%);
$danger-extra-light: #fce9e6;

$purple-extra-dark: #141233;
$purple-dark: #352c53;
$purple: #7a33ef;
$purple-light: #ae7cff;
$purple-extra-light: #decbff;

$yellow: #f9cc35;
$yellow-light: #fde182;
$yellow-extra-light: #fff7da;

$orange: #f98732;

$dark: #121e2e;

$body-bg: #f5f5f5;
$body-color: $gray-900;

$text: $gray-900;
$disabled: $gray-400;
$overlay: rgba(37, 52, 73, 0.7);

// Remove Bootstraps css variables
$grays: ();
$colors: ();
$theme-colors-rgb: ();

// Generate css variables (--css-primary, --css-secondary...)
$theme-colors: (
    'primary-extra-light': $primary-extra-light,
    'primary-light': $primary-light,
    'primary': $primary,
    'secondary': $gray-600,
    'secondary-extra-light': $gray-200,
    'secondary-light': $gray-400,
    'secondary-dark': $gray-900,
    'info-extra-light': $info-extra-light,
    'info-light': $info-light,
    'info': $info,
    'success-extra-light': $success-extra-light,
    'success-light': $success-light,
    'success': $success,
    'warning-extra-light': $warning-extra-light,
    'warning-light': $warning-light,
    'warning': $warning,
    'danger-extra-light': $danger-extra-light,
    'danger-light': $danger-light,
    'danger': $danger,
    'gray-200': $gray-200,
    'gray-300': $gray-300,
    'gray-400': $gray-400,
    'gray-500': $gray-500,
    'gray-600': $gray-600,
    'gray-700': $gray-700,
    'gray-800': $gray-800,
    'gray-900': $gray-900,
    'purple-extra-dark': $purple-extra-dark,
    'purple-dark': $purple-dark,
    'purple': $purple,
    'purple-light': $purple-light,
    'purple-extra-light': $purple-extra-light,
    'yellow': $yellow,
    'yellow-light': $yellow-light,
    'yellow-extra-light': $yellow-extra-light,
    'white': $white,
    'dark': $dark
);

// box-shadow: 0px -4px 33px 0px #121E2E14;

// box-shadow: 0px 8px 24px 0px #2534490F;
// box-shadow: 0px 2px 4px 0px #25344914;

$shadow-1: 0 3px 6px rgba(54, 54, 66, 0.1);
$shadow-2: 0 2px 4px rgba(54, 54, 66, 0.08);
$shadow-3: 0 2px 6px rgba(54, 54, 66, 0.16);
$shadow-4:
    rgba(0, 0, 0, 0.15) 0px 11px 15px -7px,
    rgba(0, 0, 0, 0.15) 0px 24px 38px 3px,
    rgba(0, 0, 0, 0.15) 0px 9px 46px 8px;
$shadow-5:
    0px 2px 4px rgba(37, 52, 73, 0.08),
    0px 8px 24px rgba(37, 52, 73, 0.06);
$shadow-6: 0px -4px 33px 0px rgba(18, 30, 46, 0.08);
//$shadow-4: 0 3px 5px -1px rgba(0, 0, 0, 0.1), 0 6px 10px 0px rgba(0, 0, 0, 0.07), 0 1px 18px 0px rgba(0, 0, 0, 0.06);

// Typography
$font-size: 16px;

$line-height-base: 1.5;
$line-height-sm: 1.25;
$line-height-lg: 2;

$font: 'Inter', $font-family-sans-serif;
$font-monospace: $font-family-monospace;

$font-weight-normal: 500;
$font-weight-bold: 700;

$font-size-xxs: 0.625rem; // 10px
$font-size-xs: 0.75rem; // 12px
$font-size-sm: 0.875rem; // 14px
$font-size-lg: 1.25rem; // 20px
$font-size-xl: 1.5rem; // 24px
$font-size-xxl: 2rem; // 32px
$font-size-xxxl: 2.625rem; // 42px

$letter-spacing-base: -0.03em;
$letter-spacing-normal: normal;
$letter-spacing-sparse: 0.03em;

// Bootstrap Grid
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 1024px,
    xl: 1260px,
    xxl: 1420px
);

$container-max-widths: (
    sm: 540px,
    md: 744px,
    lg: 1000px,
    xl: 1200px,
    xxl: 1360px
);

// Border radius
$border-radius-xs: 0.25rem; // 4px
$border-radius-sm: 0.5rem; // 8px
$border-radius-md: 0.75rem; // 12px
$border-radius-lg: 1rem; // 16px
$border-radius-xl: 1.5rem; // 24px
$border-radius-round: 50%;

// Block sizes
$width-sidebar: 200px;

$height-header: 68px;
$height-header-phone: 48px;

// Transitions
$link-transition:
    color 150ms ease-in-out,
    border-color 150ms ease-in-out;
$button-transition:
    color 150ms ease-in-out,
    background-color 150ms ease-in-out,
    border-color 150ms ease-in-out,
    box-shadow 150ms ease-in-out;
$icon-transition: fill 150ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
$linear-transition: 250ms linear;

// Z-index
$z-index-dropdown: 1000;
//$z-index-sticky: 1020;
$z-index-fixed: 1030;
//$z-index-offcanvas-backdrop: 1040;
//$z-index-offcanvas: 1045;
//$z-index-modal-backdrop: 1050;
$z-index-modal: 1055;
$z-index-popover: 1070;
$z-index-tooltip: 1080;
$z-index-toast: 1090;

@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';

:root {
    --#{$prefix}font-primary: #{$font};

    --#{$prefix}border-radius-xs: #{$border-radius-xs};
    --#{$prefix}border-radius-sm: #{$border-radius-sm};
    --#{$prefix}border-radius-md: #{$border-radius-md};
    --#{$prefix}border-radius-lg: #{$border-radius-lg};
    --#{$prefix}border-radius-xl: #{$border-radius-xl};
    --#{$prefix}border-radius-round: #{$border-radius-round};
}

%focus {
    outline: transparent solid 2px;
    box-shadow: 0 0 0 2px $info;
}

%focus-inset {
    outline: transparent solid 2px;
    box-shadow: 0 0 0 2px $info inset;
}

%container {
    max-width: map-get($container-max-widths, xxl);
    width: 100%;
    margin: 0 auto;
    padding-right: 1rem;
    padding-left: 1rem;

    @include media-breakpoint-up(md) {
        padding-right: 1.5rem;
        padding-left: 1.5rem;
    }

    @include media-breakpoint-up(xl) {
        padding-right: 2rem;
        padding-left: 2rem;
    }

    &_fluid {
        max-width: 100%;
    }
}

%text-ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
}

@mixin font-regular-xxs {
    font-size: $font-size-xxs;
    font-weight: $font-weight-normal;
    letter-spacing: $letter-spacing-base;
    line-height: $line-height-base;
}

@mixin font-regular-xs {
    font-size: $font-size-xs;
    font-weight: $font-weight-normal;
    letter-spacing: $letter-spacing-base;
    line-height: $line-height-base;
}

@mixin font-regular-sm {
    font-size: $font-size-sm;
    font-weight: $font-weight-normal;
    letter-spacing: $letter-spacing-base;
    line-height: $line-height-base;
}

@mixin font-regular-md {
    font-size: $font-size-base;
    font-weight: $font-weight-normal;
    letter-spacing: $letter-spacing-base;
    line-height: $line-height-base;
}

@mixin font-regular-lg {
    font-size: $font-size-lg;
    font-weight: $font-weight-normal;
    letter-spacing: $letter-spacing-base;
    line-height: $line-height-base;
}

@mixin font-regular-xl {
    font-size: $font-size-xl;
    font-weight: $font-weight-normal;
    letter-spacing: $letter-spacing-base;
    line-height: $line-height-base;
}

@mixin font-regular-xxl {
    font-size: $font-size-xxl;
    font-weight: $font-weight-normal;
    letter-spacing: $letter-spacing-base;
    line-height: $line-height-base;
}

@mixin font-regular-xxxl {
    font-size: $font-size-xxxl;
    font-weight: $font-weight-normal;
    letter-spacing: $letter-spacing-base;
    line-height: $line-height-base;
}

@mixin font-bold-xxs {
    font-size: $font-size-xxs;
    font-weight: $font-weight-bold;
    letter-spacing: $letter-spacing-base;
    line-height: $line-height-base;
}

@mixin font-bold-xs {
    font-size: $font-size-xs;
    font-weight: $font-weight-bold;
    letter-spacing: $letter-spacing-base;
    line-height: $line-height-base;
}

@mixin font-bold-sm {
    font-size: $font-size-sm;
    font-weight: $font-weight-bold;
    letter-spacing: $letter-spacing-base;
    line-height: $line-height-base;
}

@mixin font-bold-md {
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
    letter-spacing: $letter-spacing-base;
    line-height: $line-height-base;
}

@mixin font-bold-lg {
    font-size: $font-size-lg;
    font-weight: $font-weight-bold;
    letter-spacing: $letter-spacing-base;
    line-height: $line-height-base;
}

@mixin font-bold-xl {
    font-size: $font-size-xl;
    font-weight: $font-weight-bold;
    letter-spacing: $letter-spacing-base;
    line-height: $line-height-base;
}

@mixin font-bold-xxl {
    font-size: $font-size-xxl;
    font-weight: $font-weight-bold;
    letter-spacing: $letter-spacing-base;
    line-height: $line-height-base;
}

@mixin font-bold-xxxl {
    font-size: $font-size-xxxl;
    font-weight: $font-weight-bold;
    letter-spacing: $letter-spacing-base;
    line-height: $line-height-base;
}

@mixin disableScrollbar {
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}

@mixin mouse-only {
    @media (hover: hover) and (pointer: fine) {
        @content;
    }
}

@mixin hover {
    // Mouse and touchpad
    @media (hover: hover) and (pointer: fine) {
        &:hover {
            @content;
        }
    }
}

// Touch devices
@media (hover: none) and (pointer: coarse) {
}

@mixin reset-button {
    @include font-regular-sm;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0;
    margin: 0;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    text-transform: none;
    box-shadow: none;
    min-width: auto;
    transition: $button-transition;
    background: transparent;
    border: none;
    font-weight: $font-weight-normal;
}
