@import 'styles/variables';

.Root {
    width: 1em;
    height: 1em;
    display: inline-block;
}

.Svg {
    display: block;
}

.Circle {
    stroke: currentcolor;
}

.Variant {
    &-circle {
        &:not(.Definite) {
            animation: 1.4s linear 0s infinite normal none running rotate;

            &:not(.ShrinkDisable) .Circle {
                animation: 1.4s ease-in-out 0s infinite normal none running dash;
            }
        }

        &.Definite {
            transform: rotate(-90deg);

            .Circle {
                transition: stroke-dashoffset 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            }
        }
    }

    &-linear {
        display: block;
        position: relative;
        width: 100%;
        overflow: hidden;
        z-index: 0;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: currentColor;
            opacity: 0.3;
        }

        &:not(.Definite) {
            .BarFirst,
            .BarSecond {
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                right: 0;
                transition: transform 0.2s linear 0s;
                transform-origin: left center;
                background-color: currentColor;
            }

            .BarFirst {
                animation: 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) 0s infinite normal none running translate-slow;
            }

            .BarSecond {
                transform: translateX(-200%) scaleX(0.5);
                animation: 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) 1.15s infinite normal none running translate-fast;
            }
        }

        &.Definite {
            .BarFirst {
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                width: 100%;
                transition: transform 0.4s linear 0s;
                transform-origin: left center;
                background-color: currentColor;
            }
        }
    }
}

.Color {
    &-inherit {
        color: inherit;
    }

    &-primary {
        color: $primary;
    }

    &-secondary {
        color: $secondary;
    }

    &-success {
        color: $success;
    }

    &-danger {
        color: $danger;
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1px, 200px;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 100px, 200px;
        stroke-dashoffset: -15px;
    }

    100% {
        stroke-dasharray: 100px, 200px;
        stroke-dashoffset: -125px;
    }
}

@keyframes translate-slow {
    0% {
        transform: translateX(-35%) scaleX(0.5);
    }

    60% {
        transform: translateX(100%) scaleX(0.2);
    }

    100% {
        transform: translateX(100%) scaleX(0.2);
    }
}

@keyframes translate-fast {
    0% {
        transform: translateX(-200%) scaleX(0.5);
    }

    60% {
        transform: translateX(110%) scaleX(0.2);
    }

    100% {
        transform: translateX(100%) scaleX(0.2);
    }
}
