@import 'styles/variables';

.Nav {
    @include media-breakpoint-down(lg) {
        display: none;
    }

    &List {
        display: flex;
        list-style-type: none;
        margin-bottom: 0;
        padding-left: 0;
    }

    &Item {
        margin-right: 0.25rem;
    }

    &Link {
        &.active {
            outline: 2px solid red;
        }

        background-color: transparent;
        color: $gray-600;

        &:active {
            background-color: $gray-700;
        }

        &Active {
            cursor: default;
            color: $white;
            background-color: $gray-800;

            &:hover,
            &:active {
                background-color: $gray-800;
            }
        }

        &Badge {
            background-color: $red;
            color: $white;
            @include font-bold-xxs;
            display: flex;
            place-content: center;
            align-items: center;
            min-width: 1.063rem;
            line-height: 1;
            padding: 0 0.375rem;
            height: 1.063rem;
            border-radius: 0.625rem;
        }
    }
}

.Bar {
    display: none;

    @include media-breakpoint-down(lg) {
        display: block;
        position: fixed;
        box-shadow: 0px -4px 33px rgba(18, 30, 46, 0.08);
        bottom: 0;
        left: 0;
        right: 0;
        background-color: $white;
        z-index: $z-index-fixed;

        &Nav {
            &List {
                display: flex;
                list-style-type: none;
                margin-bottom: 0;
                padding-left: 0;
            }

            &Item {
                width: 100%;
                text-align: center;

                &HideOnMobileLandscape {
                    @include media-breakpoint-down(md) {
                        display: none;
                    }
                }

                &ShowOnMobileLandscape {
                    display: none;

                    @include media-breakpoint-down(md) {
                        display: block;
                    }
                }
            }

            &Link {
                flex-direction: column;
                background-color: transparent;
                color: $gray-500;
                @include font-regular-xs;
                max-height: none;
                border-radius: 0;
                padding: 0.665rem 0;

                &:hover {
                    background-color: transparent;
                    color: $gray-600;

                    & .BarNavIconProfile {
                        background: $gray-600;
                    }
                }

                span {
                    margin-right: 0;
                    padding-bottom: 0.5rem;
                }

                &Active {
                    color: $primary;

                    &:hover {
                        color: $primary;
                    }
                }

                .BarNavIconProfile {
                    background-color: $gray-500;
                    color: $white;
                    border-radius: 50%;
                    transition: $transition-base;
                }
            }
        }
    }
}
