@import './variables';

html {
    height: 100%;
    -webkit-text-size-adjust: 100%;
    font-size: $font-size;
}

body {
    height: 100%;
    font-family: $font;
    font-weight: $font-weight-normal;
    background-color: $body-bg;
    color: $body-color;
    min-width: 320px;
    overflow-x: hidden;
}

#root {
    height: 100%;
    display: flex;
    flex-direction: column;
}

// ====================================

button {
    background: transparent;
    border: none;
    letter-spacing: -0.03em;
}

input,
select,
textarea {
    border-radius: 0;
    outline: none;
    font-family: inherit;

    &:invalid,
    &:required {
        box-shadow: none; // fix for FF
    }
}

fieldset {
    padding: 0;
    margin: 0;
    border: none;
}

// Table styles

table {
    position: relative;
    @include font-regular-sm;
}

thead {
    @include font-bold-xxs;
    text-transform: uppercase;
    color: $gray-600;
    z-index: 1;
}

tbody {
    @include font-regular-sm;
}

th {
    @include font-bold-xxs;
    text-transform: uppercase;
    padding: 0;
    color: $gray-600;
    user-select: none;
    min-height: 100%;
    text-align: center;
}

tr {
    border-bottom: 1px solid $gray-200;
}

td {
    position: relative;
}

.DragAnimation {
    animation-name: drag-form;
    // animation-duration: 1000ms;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

@keyframes drag-form {
    0% {
        transform: translateX(0);
    }
    12.5% {
        transform: translateX(-6px) rotateY(-5deg);
    }
    37.5% {
        transform: translateX(5px) rotateY(4deg);
    }
    62.5% {
        transform: translateX(-3px) rotateY(-2deg);
    }
    87.5% {
        transform: translateX(2px) rotateY(1deg);
    }
    to {
        transform: translateX(0);
    }
}

.preloader {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.preloader img {
    width: 200px;
}

// Browsers styling
// Mozilla
@supports (-moz-appearance: none) {
    // Scroll styling
    * {
        //scrollbar-color:  #ccc #eee;
        scrollbar-width: thin;
    }
}

// Chrome
// Scroll styling
.Windows {
    ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background: $gray-500;
    }
}
