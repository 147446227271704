@import 'styles/variables';

.Root {
    display: flex;
    align-items: center;

    &:focus-visible {
        @extend %focus;
        border-radius: 0.25rem;
    }

    &Disabled {
        pointer-events: none;

        &:focus-visible {
            box-shadow: none;
        }
    }
}

.Img {
    display: block;
    height: 1.625em;
    margin-right: 0.25rem;
}
