@import 'styles/variables';

.Description {
    @include font-regular-sm;
}

.Icon {
    &Wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: $primary;
        width: 2.5rem;
        height: 2.5rem;
        color: $white;
        margin-bottom: 1rem;
    }
}
