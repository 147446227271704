@import 'styles/variables';

.Preloader {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
        width: 40px;
        height: 40px;
    }
}

.Alert {
    border-radius: 0;

    &IconWrapper {
        margin-left: auto;
    }
}
