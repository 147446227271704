@import 'styles/variables';

.Component {
    flex-grow: 1;
    padding: 3rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-down(sm) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

    code {
        display: block;
        text-transform: uppercase;
        @include font-bold-sm;
        letter-spacing: 0.4px;
        color: $primary;
        margin: 0 0 0.5rem;
        font-family: inherit;
    }

    h1 {
        @include font-bold-xxxl;
        margin: 0 0 0.5rem;
        white-space: pre-wrap;
    }

    p {
        margin: 0 0 1rem;
        @include font-regular-md;
        white-space: pre-wrap;
        color: $gray-600;
    }

    button {
        @include media-breakpoint-down(sm) {
            width: 100%;
        }
    }
}

.ImageWrapper {
    flex-shrink: 1;
    margin-right: 6.25rem;

    @include media-breakpoint-down(sm) {
        margin-right: 0;
        margin-bottom: 1rem;
    }

    img {
        max-width: 100%;

        @include media-breakpoint-down(sm) {
            height: 4rem;
            width: 4rem;
        }
    }
}

.ContentWrapper {
    flex-shrink: 0;
}
