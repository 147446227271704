@import 'styles/variables';

.Root {
    display: flex;
}

.Type {
    &-default {
        border-color: $gray-900;
        background-color: $gray-900;
    }

    &-info {
        color: $info;
        border-color: $info;
        background-color: $info-extra-light;
    }

    &-warning {
        color: $warning;
        border-color: $warning;
        background-color: $warning-extra-light;
    }

    &-success {
        color: $success;
        border-color: $success;
        background-color: $success-extra-light;
    }

    &-error {
        color: $danger;
        border-color: $danger;
        background-color: $danger-extra-light;
    }
}

.Variant {
    &-standard {
        border-color: transparent;

        &.Type {
            &-default {
                background-color: $white;
            }
        }
    }

    &-filled {
        color: $white;

        &.Type {
            &-info {
                background-color: $info;
            }

            &-warning {
                background-color: $warning;
            }

            &-success {
                background-color: $success;
            }

            &-error {
                background-color: $danger;
            }
        }
    }

    &-outlined {
        border: 1px solid;
        background-color: transparent;
    }
}

.Icon {
    &Wrapper {
        display: flex;
        align-items: center;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
        margin-right: 0.75rem;

        & > svg {
            width: 1em;
            height: 1em;
        }
    }
}

.Content {
    display: inline;

    &Wrapper {
        padding-right: 0.5rem;
    }
}

.Action {
    margin-left: auto;
    padding: 0.125rem 0 0.125rem 1rem;
}

.Size {
    &-large {
        padding: 0.375rem 0.75rem;
        border-radius: $border-radius-md;

        .Content {
            &Wrapper {
                padding-top: 0.3125rem;
                padding-bottom: 0.375rem;
                @include font-regular-sm;
            }
        }

        .Icon {
            &Wrapper {
                font-size: 1.5rem;
            }
        }
    }

    &-medium {
        padding: 0.125rem 0.375rem 0.125rem 0.625rem;
        border-radius: $border-radius-md;

        .Content {
            &Wrapper {
                padding-top: 0.4375rem;
                padding-bottom: 0.4375rem;
                @include font-regular-xs;
            }
        }

        .Icon {
            &Wrapper {
                font-size: 1.25rem;
            }
        }
    }
}
