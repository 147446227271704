@import 'styles/variables';

.Root {
    background: $white;
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0 2rem;
}

.Header {
    @extend %container;

    width: 320px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3rem 0 1rem;
}

.Main {
    @extend %container;

    max-width: map-get($container-max-widths, md);
    padding: 1rem 0 3rem;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
