@import '../../styles/variables';

.Root {
    user-select: none;
    width: 1.25em;
    height: 1.25em;
    display: inline-block;
    fill: currentcolor;
    flex-shrink: 0;
    transition: $icon-transition;
}
