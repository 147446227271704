@import 'styles/variables';

.Root {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-weight: $font-weight-normal;
    font-size: $font-size-sm;
    letter-spacing: $letter-spacing-base;
    color: $gray-700;
    max-width: 100%;
}

.Container {
    width: 100%;
    display: flex;
    position: relative;
    overflow: hidden;
    color: $gray-800;
    border: 1px solid transparent;
    border-radius: $border-radius-md;
    background: $gray-200;
    align-items: center;
    transition: border 0.2s;

    &Multiline {
        padding: 0.65rem 0.5rem 0.65rem 0;

        & .Input {
            padding: 0;
            margin-left: 1rem;
            min-height: 21.21px;
        }
    }

    &[role='button'] {
        cursor: text;
    }
}

.Label {
    margin-bottom: 0.5rem;

    &Container {
        display: flex;
        position: relative;
        width: 100%;
        justify-content: space-between;
    }
}

.Input {
    border: unset;
    background-color: transparent;
    width: 100%;
    padding: 0.65rem 1rem;
    min-height: 42px;
    text-align: left;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        box-shadow: none;
        -webkit-box-shadow: 0 0 0 5000px $gray-200 inset;
        transition: background-color 5000s ease-in-out 0s;
        -webkit-text-fill-color: currentColor !important;
        caret-color: currentColor;
    }

    &::placeholder {
        color: $gray-600;
        caret-color: $gray-800 !important;
    }

    &WithAdornmentEnd {
        padding-right: 0;
    }

    &HideResize {
        resize: none;
    }
}

.Adornment {
    display: flex;
    align-items: center;
    white-space: nowrap;
    color: $gray-600;
    z-index: 1;

    &Start {
        margin-left: 0.5rem;
        margin-right: -0.5rem;
    }

    &End {
        margin-right: 0.5rem;
    }
}

.Color {
    &-primary {
        & .Container {
            &:hover {
                border: 1px solid $primary-light;
            }

            &:focus,
            &:focus-within {
                border: 1px solid $primary;
            }
        }
    }

    &-danger {
        & .Container {
            &:hover {
                border: 1px solid $danger-light;
            }

            &:focus,
            &:focus-within {
                border: 1px solid $danger;
            }
        }
    }
}

.Disabled {
    .Label,
    .Container,
    .HelperText {
        opacity: 0.3;
        pointer-events: none;
        user-select: none;
    }
}

.Textarea {
    & .AdornmentEnd {
        margin-top: -0.25rem;
        margin-bottom: -0.25rem;
        margin-right: 0;
    }

    & .AdornmentStart {
        margin-top: -0.25rem;
        margin-bottom: -0.25rem;
    }
}

.TextareaHidden {
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    white-space: pre-wrap;
    overflow: hidden;
    padding: 0;
    border: 0;
    transform: translateZ(0);
}

.HelperText {
    overflow-wrap: anywhere;
    margin-top: 0.5rem;
}

.FullWidth {
    width: 100%;
}

.Error {
    & .Container {
        border: 1px solid $danger;

        &:hover {
            border-color: $danger;
        }

        &:focus,
        &:focus-within {
            border-color: $danger;
        }
    }

    .HelperText {
        color: $danger;
    }
}

.Dark {
    .Container {
        background: $gray-900;
        border: 1px solid $gray-900;
    }

    .Label {
        color: $gray-500;
    }

    .Input {
        color: $white;

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 5000px $gray-900 inset;
            -webkit-text-fill-color: $white !important;
            caret-color: $white !important;
        }
    }
}
